import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  DefaultPageHeader: () => import('../../src/components/layout/DefaultPageHeader.vue' /* webpackChunkName: "components/default-page-header" */).then(c => wrapFunctional(c.default || c)),
  HorizontalScrollSlider: () => import('../../src/components/layout/HorizontalScrollSlider.vue' /* webpackChunkName: "components/horizontal-scroll-slider" */).then(c => wrapFunctional(c.default || c)),
  ShowHeader: () => import('../../src/components/layout/ShowHeader.vue' /* webpackChunkName: "components/show-header" */).then(c => wrapFunctional(c.default || c)),
  AdaptiveImage: () => import('../../src/components/media/AdaptiveImage.vue' /* webpackChunkName: "components/adaptive-image" */).then(c => wrapFunctional(c.default || c)),
  AdaptiveImageNew: () => import('../../src/components/media/AdaptiveImageNew.vue' /* webpackChunkName: "components/adaptive-image-new" */).then(c => wrapFunctional(c.default || c)),
  AudioPlayer: () => import('../../src/components/media/AudioPlayer.vue' /* webpackChunkName: "components/audio-player" */).then(c => wrapFunctional(c.default || c)),
  DownloadableFile: () => import('../../src/components/media/DownloadableFile.vue' /* webpackChunkName: "components/downloadable-file" */).then(c => wrapFunctional(c.default || c)),
  HighResolutionImage: () => import('../../src/components/media/HighResolutionImage.vue' /* webpackChunkName: "components/high-resolution-image" */).then(c => wrapFunctional(c.default || c)),
  InlineVideo: () => import('../../src/components/media/InlineVideo.vue' /* webpackChunkName: "components/inline-video" */).then(c => wrapFunctional(c.default || c)),
  ModalImage: () => import('../../src/components/media/ModalImage.vue' /* webpackChunkName: "components/modal-image" */).then(c => wrapFunctional(c.default || c)),
  PlayVideoWrapper: () => import('../../src/components/media/PlayVideoWrapper.vue' /* webpackChunkName: "components/play-video-wrapper" */).then(c => wrapFunctional(c.default || c)),
  ArticleLink: () => import('../../src/components/misc/ArticleLink.vue' /* webpackChunkName: "components/article-link" */).then(c => wrapFunctional(c.default || c)),
  Disclosure: () => import('../../src/components/misc/Disclosure.vue' /* webpackChunkName: "components/disclosure" */).then(c => wrapFunctional(c.default || c)),
  IntersectionObserver: () => import('../../src/components/misc/IntersectionObserver.vue' /* webpackChunkName: "components/intersection-observer" */).then(c => wrapFunctional(c.default || c)),
  Mount: () => import('../../src/components/misc/LazyMount.vue' /* webpackChunkName: "components/lazy-mount" */).then(c => wrapFunctional(c.default || c)),
  LoadingSpinner: () => import('../../src/components/misc/LoadingSpinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c)),
  Logotype: () => import('../../src/components/misc/Logotype.vue' /* webpackChunkName: "components/logotype" */).then(c => wrapFunctional(c.default || c)),
  NuxtLinkExternal: () => import('../../src/components/misc/NuxtLinkExternal.vue' /* webpackChunkName: "components/nuxt-link-external" */).then(c => wrapFunctional(c.default || c)),
  SkipToContent: () => import('../../src/components/misc/SkipToContent.vue' /* webpackChunkName: "components/skip-to-content" */).then(c => wrapFunctional(c.default || c)),
  Checkbox: () => import('../../src/components/forms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c)),
  Input: () => import('../../src/components/forms/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c)),
  Radio: () => import('../../src/components/forms/Radio.vue' /* webpackChunkName: "components/radio" */).then(c => wrapFunctional(c.default || c)),
  Textarea: () => import('../../src/components/forms/Textarea.vue' /* webpackChunkName: "components/textarea" */).then(c => wrapFunctional(c.default || c)),
  NavigationLink: () => import('../../src/components/navigation/NavigationLink.vue' /* webpackChunkName: "components/navigation-link" */).then(c => wrapFunctional(c.default || c)),
  NavigationState: () => import('../../src/components/navigation/NavigationState.ts' /* webpackChunkName: "components/navigation-state" */).then(c => wrapFunctional(c.default || c)),
  ScenkonstSite: () => import('../../src/components/navigation/ScenkonstSite.vue' /* webpackChunkName: "components/scenkonst-site" */).then(c => wrapFunctional(c.default || c)),
  SocialMediaLinks: () => import('../../src/components/navigation/SocialMediaLinks.vue' /* webpackChunkName: "components/social-media-links" */).then(c => wrapFunctional(c.default || c)),
  SubPagesNavigation: () => import('../../src/components/navigation/SubPagesNavigation.vue' /* webpackChunkName: "components/sub-pages-navigation" */).then(c => wrapFunctional(c.default || c)),
  MobileNavigation: () => import('../../src/components/navigation/MobileNavigation/MobileNavigation.vue' /* webpackChunkName: "components/mobile-navigation" */).then(c => wrapFunctional(c.default || c)),
  MobileNavigationItem: () => import('../../src/components/navigation/MobileNavigation/MobileNavigationItem.vue' /* webpackChunkName: "components/mobile-navigation-item" */).then(c => wrapFunctional(c.default || c)),
  MobileNavigationToggle: () => import('../../src/components/navigation/MobileNavigation/MobileNavigationToggle.vue' /* webpackChunkName: "components/mobile-navigation-toggle" */).then(c => wrapFunctional(c.default || c)),
  SiteFooterContactList: () => import('../../src/components/navigation/SiteFooter/ContactList.vue' /* webpackChunkName: "components/site-footer-contact-list" */).then(c => wrapFunctional(c.default || c)),
  SiteFooterPrimaryNav: () => import('../../src/components/navigation/SiteFooter/PrimaryNav.vue' /* webpackChunkName: "components/site-footer-primary-nav" */).then(c => wrapFunctional(c.default || c)),
  SiteFooterSecondaryNav: () => import('../../src/components/navigation/SiteFooter/SecondaryNav.vue' /* webpackChunkName: "components/site-footer-secondary-nav" */).then(c => wrapFunctional(c.default || c)),
  SiteFooter: () => import('../../src/components/navigation/SiteFooter/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c)),
  SiteHeaderSecondaryNav: () => import('../../src/components/navigation/SiteHeader/SecondaryNav.vue' /* webpackChunkName: "components/site-header-secondary-nav" */).then(c => wrapFunctional(c.default || c)),
  SiteHeaderSecondaryNavToggle: () => import('../../src/components/navigation/SiteHeader/SecondaryNavToggle.vue' /* webpackChunkName: "components/site-header-secondary-nav-toggle" */).then(c => wrapFunctional(c.default || c)),
  SiteHeader: () => import('../../src/components/navigation/SiteHeader/SiteHeader.vue' /* webpackChunkName: "components/site-header" */).then(c => wrapFunctional(c.default || c)),
  SiteHeaderUserPagesIconButton: () => import('../../src/components/navigation/SiteHeader/UserPagesIconButton.vue' /* webpackChunkName: "components/site-header-user-pages-icon-button" */).then(c => wrapFunctional(c.default || c)),
  MarketHypeForm: () => import('../../src/components/MarketHypeForm.vue' /* webpackChunkName: "components/market-hype-form" */).then(c => wrapFunctional(c.default || c)),
  Observer: () => import('../../src/components/Observer.vue' /* webpackChunkName: "components/observer" */).then(c => wrapFunctional(c.default || c)),
  RenderedInContainer: () => import('../../src/components/RenderedInContainer.ts' /* webpackChunkName: "components/rendered-in-container" */).then(c => wrapFunctional(c.default || c)),
  DynamicContainer: () => import('../../src/components/DynamicContainer/DynamicContainer.vue' /* webpackChunkName: "components/dynamic-container" */).then(c => wrapFunctional(c.default || c)),
  DynamicContainerProvider: () => import('../../src/components/DynamicContainer/DynamicContainerProvider.vue' /* webpackChunkName: "components/dynamic-container-provider" */).then(c => wrapFunctional(c.default || c)),
  SiteAlert: () => import('../../src/components/SiteAlert/SiteAlert.vue' /* webpackChunkName: "components/site-alert" */).then(c => wrapFunctional(c.default || c)),
  SiteAlertState: () => import('../../src/components/SiteAlert/SiteAlertState.ts' /* webpackChunkName: "components/site-alert-state" */).then(c => wrapFunctional(c.default || c)),
  SiteSearch: () => import('../../src/components/SiteSearch/SiteSearch.vue' /* webpackChunkName: "components/site-search" */).then(c => wrapFunctional(c.default || c)),
  SiteSearchClose: () => import('../../src/components/SiteSearch/SiteSearchClose.vue' /* webpackChunkName: "components/site-search-close" */).then(c => wrapFunctional(c.default || c)),
  SiteSearchInput: () => import('../../src/components/SiteSearch/SiteSearchInput.vue' /* webpackChunkName: "components/site-search-input" */).then(c => wrapFunctional(c.default || c)),
  SiteSearchLoading: () => import('../../src/components/SiteSearch/SiteSearchLoading.vue' /* webpackChunkName: "components/site-search-loading" */).then(c => wrapFunctional(c.default || c)),
  SiteSearchNav: () => import('../../src/components/SiteSearch/SiteSearchNav.vue' /* webpackChunkName: "components/site-search-nav" */).then(c => wrapFunctional(c.default || c)),
  SiteSearchResult: () => import('../../src/components/SiteSearch/SiteSearchResult.vue' /* webpackChunkName: "components/site-search-result" */).then(c => wrapFunctional(c.default || c)),
  BlockAccordion: () => import('../../src/components/block/Accordion.vue' /* webpackChunkName: "components/block-accordion" */).then(c => wrapFunctional(c.default || c)),
  BlockArticleLinksList: () => import('../../src/components/block/ArticleLinksList.vue' /* webpackChunkName: "components/block-article-links-list" */).then(c => wrapFunctional(c.default || c)),
  BlockWrapper: () => import('../../src/components/block/BlockWrapper.vue' /* webpackChunkName: "components/block-wrapper" */).then(c => wrapFunctional(c.default || c)),
  BlockContentInner: () => import('../../src/components/block/ContentInner.vue' /* webpackChunkName: "components/block-content-inner" */).then(c => wrapFunctional(c.default || c)),
  BlockContentOneColumn: () => import('../../src/components/block/ContentOneColumn.vue' /* webpackChunkName: "components/block-content-one-column" */).then(c => wrapFunctional(c.default || c)),
  BlockContentTwoColumns: () => import('../../src/components/block/ContentTwoColumns.vue' /* webpackChunkName: "components/block-content-two-columns" */).then(c => wrapFunctional(c.default || c)),
  BlockContentWithImage: () => import('../../src/components/block/ContentWithImage.vue' /* webpackChunkName: "components/block-content-with-image" */).then(c => wrapFunctional(c.default || c)),
  BlockDigitalScene: () => import('../../src/components/block/DigitalScene.vue' /* webpackChunkName: "components/block-digital-scene" */).then(c => wrapFunctional(c.default || c)),
  BlockGuidedTourForm: () => import('../../src/components/block/GuidedTourForm.vue' /* webpackChunkName: "components/block-guided-tour-form" */).then(c => wrapFunctional(c.default || c)),
  BlockHorizontalListTypes: () => import('../../src/components/block/HorizontalList.types.ts' /* webpackChunkName: "components/block-horizontal-list-types" */).then(c => wrapFunctional(c.default || c)),
  BlockHorizontalList: () => import('../../src/components/block/HorizontalList.vue' /* webpackChunkName: "components/block-horizontal-list" */).then(c => wrapFunctional(c.default || c)),
  BlockImage: () => import('../../src/components/block/Image.vue' /* webpackChunkName: "components/block-image" */).then(c => wrapFunctional(c.default || c)),
  BlockImageGallery: () => import('../../src/components/block/ImageGallery.vue' /* webpackChunkName: "components/block-image-gallery" */).then(c => wrapFunctional(c.default || c)),
  BlockKeepReading: () => import('../../src/components/block/KeepReading.vue' /* webpackChunkName: "components/block-keep-reading" */).then(c => wrapFunctional(c.default || c)),
  BlockMarketHypeSubscribe: () => import('../../src/components/block/MarketHypeSubscribe.vue' /* webpackChunkName: "components/block-market-hype-subscribe" */).then(c => wrapFunctional(c.default || c)),
  BlockNewsletter: () => import('../../src/components/block/Newsletter.vue' /* webpackChunkName: "components/block-newsletter" */).then(c => wrapFunctional(c.default || c)),
  BlockRelatedPerformances: () => import('../../src/components/block/RelatedPerformances.vue' /* webpackChunkName: "components/block-related-performances" */).then(c => wrapFunctional(c.default || c)),
  BlockShowUpcomingDates: () => import('../../src/components/block/ShowUpcomingDates.vue' /* webpackChunkName: "components/block-show-upcoming-dates" */).then(c => wrapFunctional(c.default || c)),
  BlockSponsors: () => import('../../src/components/block/Sponsors.vue' /* webpackChunkName: "components/block-sponsors" */).then(c => wrapFunctional(c.default || c)),
  BlockSpotlight: () => import('../../src/components/block/Spotlight.vue' /* webpackChunkName: "components/block-spotlight" */).then(c => wrapFunctional(c.default || c)),
  BlockSubscriptionListDetails: () => import('../../src/components/block/SubscriptionListDetails.vue' /* webpackChunkName: "components/block-subscription-list-details" */).then(c => wrapFunctional(c.default || c)),
  BlockUpcomingShows: () => import('../../src/components/block/UpcomingShows.vue' /* webpackChunkName: "components/block-upcoming-shows" */).then(c => wrapFunctional(c.default || c)),
  BlockVideoPlayer: () => import('../../src/components/block/VideoPlayer.vue' /* webpackChunkName: "components/block-video-player" */).then(c => wrapFunctional(c.default || c)),
  BlockWorkersHorizontalList: () => import('../../src/components/block/WorkersHorizontalList.vue' /* webpackChunkName: "components/block-workers-horizontal-list" */).then(c => wrapFunctional(c.default || c)),
  AnimateExpand: () => import('../../src/components/animate/AnimateExpand.vue' /* webpackChunkName: "components/animate-expand" */).then(c => wrapFunctional(c.default || c)),
  AnimateText: () => import('../../src/components/animate/AnimateText.vue' /* webpackChunkName: "components/animate-text" */).then(c => wrapFunctional(c.default || c)),
  ButtonBackToParentPage: () => import('../../src/components/button/BackToParentPage.vue' /* webpackChunkName: "components/button-back-to-parent-page" */).then(c => wrapFunctional(c.default || c)),
  Button: () => import('../../src/components/button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c)),
  ButtonCircle: () => import('../../src/components/button/Circle.vue' /* webpackChunkName: "components/button-circle" */).then(c => wrapFunctional(c.default || c)),
  CardArticle: () => import('../../src/components/card/Article.vue' /* webpackChunkName: "components/card-article" */).then(c => wrapFunctional(c.default || c)),
  CardArticleSkeleton: () => import('../../src/components/card/ArticleSkeleton.vue' /* webpackChunkName: "components/card-article-skeleton" */).then(c => wrapFunctional(c.default || c)),
  CardReview: () => import('../../src/components/card/Review.vue' /* webpackChunkName: "components/card-review" */).then(c => wrapFunctional(c.default || c)),
  CardShow: () => import('../../src/components/card/Show.vue' /* webpackChunkName: "components/card-show" */).then(c => wrapFunctional(c.default || c)),
  CardSmaller: () => import('../../src/components/card/Smaller.vue' /* webpackChunkName: "components/card-smaller" */).then(c => wrapFunctional(c.default || c)),
  CardWorker: () => import('../../src/components/card/Worker.vue' /* webpackChunkName: "components/card-worker" */).then(c => wrapFunctional(c.default || c)),
  CardWorkerCategories: () => import('../../src/components/card/WorkerCategories.vue' /* webpackChunkName: "components/card-worker-categories" */).then(c => wrapFunctional(c.default || c)),
  CardWorkerSimple: () => import('../../src/components/card/WorkerSimple.vue' /* webpackChunkName: "components/card-worker-simple" */).then(c => wrapFunctional(c.default || c)),
  CardWorkers: () => import('../../src/components/card/Workers.vue' /* webpackChunkName: "components/card-workers" */).then(c => wrapFunctional(c.default || c)),
  IconsCheckCircle: () => import('../../src/components/icons/check-circle.vue' /* webpackChunkName: "components/icons-check-circle" */).then(c => wrapFunctional(c.default || c)),
  IconsPause: () => import('../../src/components/icons/pause.vue' /* webpackChunkName: "components/icons-pause" */).then(c => wrapFunctional(c.default || c)),
  IconsPlay: () => import('../../src/components/icons/play.vue' /* webpackChunkName: "components/icons-play" */).then(c => wrapFunctional(c.default || c)),
  IconsSpotify: () => import('../../src/components/icons/spotify.vue' /* webpackChunkName: "components/icons-spotify" */).then(c => wrapFunctional(c.default || c)),
  Modal: () => import('../../src/components/modal/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c)),
  ModalVideoPlayer: () => import('../../src/components/modal/VideoPlayer.vue' /* webpackChunkName: "components/modal-video-player" */).then(c => wrapFunctional(c.default || c)),
  SkeletonRect: () => import('../../src/components/skeleton/Rect.vue' /* webpackChunkName: "components/skeleton-rect" */).then(c => wrapFunctional(c.default || c)),
  SupportIe11Render: () => import('../../src/components/support/Ie11Render.vue' /* webpackChunkName: "components/support-ie11-render" */).then(c => wrapFunctional(c.default || c)),
  AnimateBodyBackground: () => import('../../src/components/animate/BodyBackground/BodyBackground.vue' /* webpackChunkName: "components/animate-body-background" */).then(c => wrapFunctional(c.default || c)),
  AnimateBodyBackgroundState: () => import('../../src/components/animate/BodyBackground/BodyBackgroundState.ts' /* webpackChunkName: "components/animate-body-background-state" */).then(c => wrapFunctional(c.default || c)),
  AnimateBodyBackgroundContrastUtils: () => import('../../src/components/animate/BodyBackground/contrast-utils.ts' /* webpackChunkName: "components/animate-body-background-contrast-utils" */).then(c => wrapFunctional(c.default || c)),
  AnimateSiteReveal: () => import('../../src/components/animate/SiteReveal/SiteReveal.vue' /* webpackChunkName: "components/animate-site-reveal" */).then(c => wrapFunctional(c.default || c)),
  AnimateSiteRevealState: () => import('../../src/components/animate/SiteReveal/SiteRevealState.ts' /* webpackChunkName: "components/animate-site-reveal-state" */).then(c => wrapFunctional(c.default || c)),
  ModalImageGallery: () => import('../../src/components/modal/ImageGallery/ImageGallery.vue' /* webpackChunkName: "components/modal-image-gallery" */).then(c => wrapFunctional(c.default || c)),
  ModalImageGalleryItem: () => import('../../src/components/modal/ImageGallery/Item.vue' /* webpackChunkName: "components/modal-image-gallery-item" */).then(c => wrapFunctional(c.default || c)),
  ModalImageGalleryWrapper: () => import('../../src/components/modal/ImageGallery/Wrapper.vue' /* webpackChunkName: "components/modal-image-gallery-wrapper" */).then(c => wrapFunctional(c.default || c)),
  ShowsCalendar: () => import('../../src/components/shows/Calendar.vue' /* webpackChunkName: "components/shows-calendar" */).then(c => wrapFunctional(c.default || c)),
  ShowsDateListItem: () => import('../../src/components/shows/DateListItem.vue' /* webpackChunkName: "components/shows-date-list-item" */).then(c => wrapFunctional(c.default || c)),
  ShowsDateListItemSkeleton: () => import('../../src/components/shows/DateListItemSkeleton.vue' /* webpackChunkName: "components/shows-date-list-item-skeleton" */).then(c => wrapFunctional(c.default || c)),
  ShowsDisclaimer: () => import('../../src/components/shows/Disclaimer.vue' /* webpackChunkName: "components/shows-disclaimer" */).then(c => wrapFunctional(c.default || c)),
  ShowsFilter: () => import('../../src/components/shows/Filter.vue' /* webpackChunkName: "components/shows-filter" */).then(c => wrapFunctional(c.default || c)),
  ShowsListItem: () => import('../../src/components/shows/ListItem.vue' /* webpackChunkName: "components/shows-list-item" */).then(c => wrapFunctional(c.default || c)),
  ShowsListItemSkeleton: () => import('../../src/components/shows/ListItemSkeleton.vue' /* webpackChunkName: "components/shows-list-item-skeleton" */).then(c => wrapFunctional(c.default || c)),
  ShowsOMBookButton: () => import('../../src/components/shows/OMBookButton.vue' /* webpackChunkName: "components/shows-o-m-book-button" */).then(c => wrapFunctional(c.default || c)),
  ShowsPlacesFilter: () => import('../../src/components/shows/PlacesFilter.vue' /* webpackChunkName: "components/shows-places-filter" */).then(c => wrapFunctional(c.default || c)),
  ShowsStatusBanner: () => import('../../src/components/shows/StatusBanner.vue' /* webpackChunkName: "components/shows-status-banner" */).then(c => wrapFunctional(c.default || c)),
  ShowsXScenenBackground: () => import('../../src/components/shows/XScenenBackground.vue' /* webpackChunkName: "components/shows-x-scenen-background" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
